var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("span", { staticClass: "d-block f16 text-center" }, [
        _vm._v(_vm._s(_vm.exercise.title)),
      ]),
      _vm.exerciseProp.createDate
        ? _c(
            "span",
            {
              staticClass:
                "f13 grey--text text--darken-1 d-flex flex-row align-items-center justify-content-center mt-2",
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("Date of prescription:")) + " "),
              _c("i", { staticClass: "far fa-calendar-alt ms-2 me-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moment")(
                      _vm.exerciseProp.createDate,
                      "dddd , jD jMMMM jYYYY"
                    )
                  ) +
                  " "
              ),
              _c("i", { staticClass: "far fa-clock ms-2 me-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("moment")(_vm.exerciseProp.createDate, "HH:MM")
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _vm.showDoctorInfo
        ? _c(
            "v-card",
            {
              staticClass: "d-flex align-items-center my-2",
              attrs: { flat: "" },
            },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "ms-0 me-2 my-2 rounded-lg",
                  attrs: { size: "96" },
                },
                [
                  _c("v-img", {
                    attrs: {
                      "lazy-src": require("@/assets/images/profile-circle.png"),
                      src:
                        _vm.doctor.pic != null && _vm.doctor.pic != ""
                          ? _vm.doctor.pic
                          : require("@/assets/images/profile.jpg"),
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "ms-2 me-0 d-flex flex-column" }, [
                _c("span", { staticClass: "f14 font-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.doctor.firstname + " " + _vm.doctor.lastname) +
                      " "
                  ),
                ]),
                _c(
                  "span",
                  { staticClass: "mt-1 f13 primary--text text--darken-1" },
                  [_vm._v(" " + _vm._s(_vm.doctor.clinic.name) + " ")]
                ),
                _c(
                  "span",
                  { staticClass: "mt-1 f13 grey--text text--darken-1" },
                  [_vm._v(" " + _vm._s(_vm.doctor.specialties[0].value) + " ")]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "grey lighten-4 rounded-lg text-dark px-4 py-2" },
        [
          _c("span", { staticClass: "f15 fw-bold d-block" }, [
            _vm._v(_vm._s(_vm.$t("Description"))),
          ]),
          _c("p", {
            staticClass: "f14 pa-2 m-0",
            domProps: { innerHTML: _vm._s(_vm.exercise.description) },
          }),
        ]
      ),
      _c(
        "v-footer",
        {
          staticClass: "pb-2 pt-0",
          attrs: { app: "", paddless: "", color: "white" },
        },
        [
          _c("v-container", { attrs: { fluid: "" } }, [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "rounded-lg",
                    attrs: { color: "primary", large: "", block: "" },
                    on: {
                      click: function ($event) {
                        return _vm.handleNextPage(_vm.exerciseProp)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Start")) + " ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }